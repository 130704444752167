import { getBffVersionBySsr } from 'public/src/pages/user/child_pages/user_bff_version/index.js'
import { parseQueryString } from '@shein/common-function'

const { langPath } = gbCommonInfo
const userCredentials = [
  {
    path: `${langPath}/credentials/index`,
    name: 'page_user_credentials',
    meta: {
      pageType: 'user_credentials',
      keepAlive: false,
      notNeedCommonHeader: true,
      isCssInVue: true,
    },
    component: () => {
      const query = parseQueryString(location.search)
      return getBffVersionBySsr('login_v5') && !query.risk_id
        ? import(/* webpackChunkName: "user_credentials-module" */ 'public/src/pages/user/child_pages/credentials_bff/index.vue')
        : import(/* webpackChunkName: "user_credentials-module" */ '../../user_credentials/index.vue')
    }
  }
]

export {
  userCredentials
}
