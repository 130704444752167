import { getBffVersionBySsr } from 'public/src/pages/user/child_pages/user_bff_version/index.js'

const { langPath } = gbCommonInfo
const emailUnbindRoutes = [
  {
    path: `${langPath}/email_unbind`,
    name: 'page_email_unbind',
    meta: {
      isCssInVue: true,
      pageType: 'email_unbind',
      keepAlive: false,
      notNeedCommonHeader: true
    },
    component: () => {
      return getBffVersionBySsr('login_v5')
        ? import(/* webpackChunkName: "email-unbind-module" */ 'public/src/pages/user/child_pages/email_unbind/container.vue')
        : import(/* webpackChunkName: "email-unbind-module" */ '../../email_unbind/container.vue')
    }
  }
]

export {
  emailUnbindRoutes
}
