import { TMGTriggerForRenderPage, TMGTriggerForLandingRenderPage } from 'public/src/pre_requests/modules/trend.js'

export const createTredRoute = (langPath = '') => {
  return [
    {
      path: `${langPath}/top-trend`,
      name: 'top_trend',
      meta: {
        keepAlive: true,
        pageType: 'top_trend',
        monitorTag: 'top_trend',
        isCssInVue: true,
        notNeedCommonHeader: true,
      },
      component: () => import( /* webpackChunkName: "top_trend" */ '../../trend/pages/TopTrend.vue'),
      beforeEnter: (to, from, next) => {
        if(typeof window !== 'undefined'){
          if(from.name && from.name != 'top_trend'){
            TMGTriggerForRenderPage(to)
          }
        }
        next()
      },
    },
    {
      path: `${langPath}/trend-landing`,
      name: 'trend_landing',
      meta: {
        keepAlive: true,
        pageType: 'trend_landing',
        monitorTag: 'trend_landing',
        isCssInVue: true,
        notNeedCommonHeader: true,
      },
      component: () => import( /* webpackChunkName: "trend_landing" */ '../../trend/pages/TrendLanding.vue'),
      beforeEnter: (to, from, next) => {
        if(typeof window !== 'undefined'){
          if(from.name && from.name != 'trend_landing'){
            TMGTriggerForLandingRenderPage(to)
          }
        }
        next()
      },
    },
    {
      path: `${langPath}/trend-outfits`,
      name: 'trend_outfits',
      meta: {
        keepAlive: true,
        pageType: 'trend_outfits',
        monitorTag: 'trend_outfits',
        isCssInVue: true,
        notNeedCommonHeader: true,
      },
      component: () => import( /* webpackChunkName: "trend_outfits" */ '../../trend/pages/TrendOutfits.vue'),
    },
    {
      path: `${langPath}/toptrend-wall`,
      name: 'toptrend_wall',
      meta: {
        keepAlive: true,
        pageType: 'toptrend_wall',
        monitorTag: 'toptrend_wall',
        isCssInVue: true,
        notNeedCommonHeader: true,
      },
      component: () => import( /* webpackChunkName: "top_trend_wall" */ '../../trend/pages/TopTrendWall.vue'),
    },
  ]
}
