export const homeRouter = {
  path: `/:langPath?/:id([A-Za-z_0-9?]+)?`,
  name: 'config_index',
  // component: () => import(/* webpackChunkName: "config_index" */ '../../config_index/components/app_v2.vue'),
  component: () => import(/* webpackChunkName: "config_index" */ '../../config_index/Home.vue'),
  meta: {
    keepAlive: true,
    pageType: 'index',
    monitorTag: 'index',
    isCssInVue: true,
    savedPosition: { left: 0, top: 0 },
    // 首页上报apm-前数据预处理方法
    fspPreReporterHandler: (data)=>{
      let { page_version } = data || {}
      if (!page_version) {
        page_version = 'Other'
      }
      let HomeProductCard = window?.gbCommonInfo?.contextForSSR?.content?.homePageExtra?.webDynamicAbt?.HomeProductCard || '0' // ssr
      if (!HomeProductCard) {
        HomeProductCard = window?._gb_app_?.$store.state?.config_index?.context?.content?.homePageExtra?.webDynamicAbt?.HomeProductCard || '0' //csr
      }
      data.page_version = `${page_version}_page_home_${HomeProductCard}`// page_home首页命中首页商卡，数据回收
      return data
    }
  }
}
